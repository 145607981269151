@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  header {
    height: 20vh;
    background-color: #000;
  }
}
@font-face {
  font-family: "Futura-Book";
  src: url("../assets/fonts/futura/Futura Book font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Futura-Book";
  src: url("../assets/fonts/futura/Futura Book Italic font.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Futura-Medium";
  src: url("../assets/fonts/futura/futura medium bt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Futura-Medium";
  src: url("../assets/fonts/futura/Futura Medium Italic font.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Futura-Bold";
  src: url("../assets/fonts/futura/Futura Bold font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Futura-Bold";
  src: url("../assets/fonts/futura/Futura Bold Italic font.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Futura-Heavy";
  src: url("../assets/fonts/futura/Futura Heavy font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Futura-Heavy";
  src: url("../assets/fonts/futura/Futura Heavy Italic font.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
#bgStyle {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #003C54;
  overflow: hidden;
}

@media screen and (min-device-width: 260px) and (max-device-width: 420px) and (max-device-height: 930px) {
  #bgStyle {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
  }
  .layer {
    position: absolute;
    top: 20%;
    left: 10%;
    width: 100%;
    height: 100vh;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);
  }
  .layer.layer1 {
    background: #00B6FE;
    transform: skewX(-45deg) translateX(0);
    z-index: 1;
  }
  .layer.layer2 {
    background: #003C54;
    transform: skewX(-45deg) translateX(-100px);
    z-index: 3;
  }
  .layer.layer3 {
    background: #0097D3;
    transform: skewX(-45deg) translateX(-200px);
    z-index: 5;
  }
  .layer.layer4 {
    background: #fff;
    transform: skewX(-45deg) translateX(-700px) translateY(-100px);
    z-index: 10;
    width: 200%;
  }
  .layer.layer5 {
    background: #0079A9;
    transform: skewX(45deg) translateX(0px);
    z-index: 2;
  }
  .layer.layer6 {
    background: #00B6FE;
    transform: skewX(45deg) translateX(-100px);
    z-index: 3;
  }
  .layer.layer7 {
    background: #0097D3;
    transform: skewX(45deg) translateX(-200px);
    z-index: 5;
  }
  .layer.layer8 {
    background: #003C54;
    transform: skewX(45deg) translateX(-300px);
    z-index: 7;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  #bgStyle {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
  }
  .layer {
    position: absolute;
    top: 15%;
    left: 10%;
    width: 100%;
    height: 100vh;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);
  }
  .layer.layer1 {
    background: #00B6FE;
    transform: skewX(-45deg) translateX(0);
    z-index: 1;
  }
  .layer.layer2 {
    background: #003C54;
    transform: skewX(-45deg) translateX(-100px);
    z-index: 3;
  }
  .layer.layer3 {
    background: #0097D3;
    transform: skewX(-45deg) translateX(-200px);
    z-index: 5;
  }
  .layer.layer4 {
    background: #fff;
    transform: skewX(-45deg) translateX(-700px) translateY(-100px);
    z-index: 10;
    width: 200%;
  }
  .layer.layer5 {
    background: #0079A9;
    transform: skewX(45deg) translateX(0px);
    z-index: 2;
  }
  .layer.layer6 {
    background: #00B6FE;
    transform: skewX(45deg) translateX(-100px);
    z-index: 3;
  }
  .layer.layer7 {
    background: #0097D3;
    transform: skewX(45deg) translateX(-200px);
    z-index: 5;
  }
  .layer.layer8 {
    background: #003C54;
    transform: skewX(45deg) translateX(-300px);
    z-index: 7;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  #bgStyle {
    background-color: #fff;
  }
  .layer {
    position: absolute;
    top: 5%;
    left: -35%;
    width: 100%;
    height: 100vh;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);
  }
  .layer.layer1 {
    background: #00B6FE;
    transform: skewX(-45deg) translateX(0);
    z-index: 1;
  }
  .layer.layer2 {
    background: #fff;
    transform: skewX(-45deg) translateX(-100px);
    z-index: 3;
  }
  .layer.layer3 {
    background: #0097D3;
    transform: skewX(-45deg) translateX(-200px);
    z-index: 5;
  }
  .layer.layer4 {
    background: #fff;
    transform: skewX(-45deg) translateX(-300px);
    z-index: 10;
  }
  .layer.layer5 {
    background: #0079A9;
    transform: skewX(45deg) translateX(0px);
    z-index: 2;
  }
  .layer.layer6 {
    background: #00B6FE;
    transform: skewX(45deg) translateX(-100px);
    z-index: 3;
  }
  .layer.layer7 {
    background: #0097D3;
    transform: skewX(45deg) translateX(-200px);
    z-index: 5;
  }
  .layer.layer8 {
    background: #003C54;
    transform: skewX(45deg) translateX(-300px);
    z-index: 7;
  }
}
@media screen and (min-device-width: 768px) {
  #bgStyle {
    background-color: #fff;
  }
  .layer {
    position: absolute;
    top: 5%;
    left: -35%;
    width: 100%;
    height: 100vh;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);
  }
  .layer.layer1 {
    background: #00B6FE;
    transform: skewX(-45deg) translateX(0);
    z-index: 1;
  }
  .layer.layer2 {
    background: #fff;
    transform: skewX(-45deg) translateX(-100px);
    z-index: 3;
  }
  .layer.layer3 {
    background: #0097D3;
    transform: skewX(-45deg) translateX(-200px);
    z-index: 5;
  }
  .layer.layer4 {
    background: #fff;
    transform: skewX(-45deg) translateX(-300px);
    z-index: 10;
  }
  .layer.layer5 {
    background: #0079A9;
    transform: skewX(45deg) translateX(0px);
    z-index: 2;
  }
  .layer.layer6 {
    background: #00B6FE;
    transform: skewX(45deg) translateX(-100px);
    z-index: 3;
  }
  .layer.layer7 {
    background: #0097D3;
    transform: skewX(45deg) translateX(-200px);
    z-index: 5;
  }
  .layer.layer8 {
    background: #003C54;
    transform: skewX(45deg) translateX(-300px);
    z-index: 7;
  }
}
@media screen and (min-device-width: 1024px) {
  .layer {
    position: absolute;
    top: 0;
    left: -15%;
    width: 100%;
    height: 100vh;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);
  }
  .layer.layer1 {
    background: #00B6FE;
    transform: skewX(-45deg) translateX(0);
    z-index: 1;
  }
  .layer.layer2 {
    background: #fff;
    transform: skewX(-45deg) translateX(-100px);
    z-index: 3;
  }
  .layer.layer3 {
    background: #0097D3;
    transform: skewX(-45deg) translateX(-200px);
    z-index: 5;
  }
  .layer.layer4 {
    background: #fff;
    transform: skewX(-45deg) translateX(-300px);
    z-index: 10;
  }
  .layer.layer5 {
    background: #0079A9;
    transform: skewX(45deg) translateX(0px);
    z-index: 2;
  }
  .layer.layer6 {
    background: #00B6FE;
    transform: skewX(45deg) translateX(-100px);
    z-index: 3;
  }
  .layer.layer7 {
    background: #0097D3;
    transform: skewX(45deg) translateX(-200px);
    z-index: 5;
  }
  .layer.layer8 {
    background: #003C54;
    transform: skewX(45deg) translateX(-300px);
    z-index: 7;
  }
}
@media screen and (min-width: 1280px) {
  .layer {
    position: absolute;
    top: 0;
    left: -20%;
    width: 100%;
    height: 100vh;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);
  }
  .layer.layer1 {
    background: #00B6FE;
    transform: skewX(-45deg) translateX(0);
    z-index: 1;
  }
  .layer.layer2 {
    background: #fff;
    transform: skewX(-45deg) translateX(-100px);
    z-index: 3;
  }
  .layer.layer3 {
    background: #0097D3;
    transform: skewX(-45deg) translateX(-200px);
    z-index: 5;
  }
  .layer.layer4 {
    background: #fff;
    transform: skewX(-45deg) translateX(-300px);
    z-index: 10;
  }
  .layer.layer5 {
    background: #0079A9;
    transform: skewX(45deg) translateX(0px);
    z-index: 2;
  }
  .layer.layer6 {
    background: #00B6FE;
    transform: skewX(45deg) translateX(-100px);
    z-index: 3;
  }
  .layer.layer7 {
    background: #0097D3;
    transform: skewX(45deg) translateX(-200px);
    z-index: 5;
  }
  .layer.layer8 {
    background: #003C54;
    transform: skewX(45deg) translateX(-300px);
    z-index: 7;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  font-family: "Futura-Book";
  box-sizing: border-box;
}

body {
  background: #001E29;
  width: 100%;
}/*# sourceMappingURL=style.css.map */