#bgStyle {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $bg-ads-darker;
    overflow: hidden;
}
// Mobile
@media screen and (min-device-width: 260px) and (max-device-width: 420px) and (max-device-height: 930px) {
    #bgStyle {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow: hidden;
    }    
    .layer {
        position: absolute;
        top: 20%;
        left: 10%;
        width: 100%;
        height: 100vh;
        box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);
    }
    .layer.layer1 {
        background: $bg-ads-brighter;
        transform: skewX(-45deg) translateX(0);
        z-index: 1;
    }
    .layer.layer2 {
        background: $bg-ads-darker;
        transform: skewX(-45deg) translateX(-100px);
        z-index: 3;
    }
    .layer.layer3 {
        background: $bg-ads-bright;
        transform: skewX(-45deg) translateX(-200px);
        z-index: 5;
    }
    .layer.layer4 {
        background: #fff;
        transform: skewX(-45deg) translateX(-700px) translateY(-100px);
        z-index: 10;
        width: 200%;
    }

    .layer.layer5 {
        background: $bg-ads-og;
        transform: skewX(45deg) translateX(0px);
        z-index: 2;
    }
    .layer.layer6 {
        background: $bg-ads-brighter;
        transform: skewX(45deg) translateX(-100px);
        z-index: 3;
    }
    .layer.layer7 {
        background: $bg-ads-bright;
        transform: skewX(45deg) translateX(-200px);
        z-index: 5;
    }
    .layer.layer8 {
        background: $bg-ads-darker;
        transform: skewX(45deg) translateX(-300px);
        z-index: 7;
    }
}
// Iphone x portrait
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    #bgStyle {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow: hidden;
    }
    .layer {
        position: absolute;
        top: 15%;
        left: 10%;
        width: 100%;
        height: 100vh;
        box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);
    }
    .layer.layer1 {
        background: $bg-ads-brighter;
        transform: skewX(-45deg) translateX(0);
        z-index: 1;
    }
    .layer.layer2 {
        background: $bg-ads-darker;
        transform: skewX(-45deg) translateX(-100px);
        z-index: 3;
    }
    .layer.layer3 {
        background: $bg-ads-bright;
        transform: skewX(-45deg) translateX(-200px);
        z-index: 5;
    }
    .layer.layer4 {
        background: #fff;
        transform: skewX(-45deg) translateX(-700px) translateY(-100px);
        z-index: 10;
        width: 200%;
    }

    .layer.layer5 {
        background: $bg-ads-og;
        transform: skewX(45deg) translateX(0px);
        z-index: 2;
    }
    .layer.layer6 {
        background: $bg-ads-brighter;
        transform: skewX(45deg) translateX(-100px);
        z-index: 3;
    }
    .layer.layer7 {
        background: $bg-ads-bright;
        transform: skewX(45deg) translateX(-200px);
        z-index: 5;
    }
    .layer.layer8 {
        background: $bg-ads-darker;
        transform: skewX(45deg) translateX(-300px);
        z-index: 7;
    }
}
// Iphone X landscape
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    #bgStyle {
        background-color: #fff;
    }
    .layer {
        position: absolute;
        top: 5%;
        left: -35%;
        width: 100%;
        height: 100vh;
        box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);
    }
    .layer.layer1 {
        background: $bg-ads-brighter;
        transform: skewX(-45deg) translateX(0);
        z-index: 1;
    }
    .layer.layer2 {
        background: #fff;
        transform: skewX(-45deg) translateX(-100px);
        z-index: 3;
    }
    .layer.layer3 {
        background: $bg-ads-bright;
        transform: skewX(-45deg) translateX(-200px);
        z-index: 5;
    }
    .layer.layer4 {
        background: #fff;
        transform: skewX(-45deg) translateX(-300px);
        z-index: 10;
    }

    .layer.layer5 {
        background: $bg-ads-og;
        transform: skewX(45deg) translateX(0px);
        z-index: 2;
    }
    .layer.layer6 {
        background: $bg-ads-brighter;
        transform: skewX(45deg) translateX(-100px);
        z-index: 3;
    }
    .layer.layer7 {
        background: $bg-ads-bright;
        transform: skewX(45deg) translateX(-200px);
        z-index: 5;
    }
    .layer.layer8 {
        background: $bg-ads-darker;
        transform: skewX(45deg) translateX(-300px);
        z-index: 7;
    }
}
// Tablet
@media screen and (min-device-width: 768px) {
    #bgStyle {
        background-color: #fff;
    }
    .layer {
        position: absolute;
        top: 5%;
        left: -35%;
        width: 100%;
        height: 100vh;
        box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);
    }
    .layer.layer1 {
        background: $bg-ads-brighter;
        transform: skewX(-45deg) translateX(0);
        z-index: 1;
    }
    .layer.layer2 {
        background: #fff;
        transform: skewX(-45deg) translateX(-100px);
        z-index: 3;
    }
    .layer.layer3 {
        background: $bg-ads-bright;
        transform: skewX(-45deg) translateX(-200px);
        z-index: 5;
    }
    .layer.layer4 {
        background: #fff;
        transform: skewX(-45deg) translateX(-300px);
        z-index: 10;
    }

    .layer.layer5 {
        background: $bg-ads-og;
        transform: skewX(45deg) translateX(0px);
        z-index: 2;
    }
    .layer.layer6 {
        background: $bg-ads-brighter;
        transform: skewX(45deg) translateX(-100px);
        z-index: 3;
    }
    .layer.layer7 {
        background: $bg-ads-bright;
        transform: skewX(45deg) translateX(-200px);
        z-index: 5;
    }
    .layer.layer8 {
        background: $bg-ads-darker;
        transform: skewX(45deg) translateX(-300px);
        z-index: 7;
    }
}
// Tablet Landscape
@media screen and (min-device-width: 1024px) {
    .layer {
        position: absolute;
        top: 0;
        left: -15%;
        width: 100%;
        height: 100vh;
        box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);
    }
    .layer.layer1 {
        background: $bg-ads-brighter;
        transform: skewX(-45deg) translateX(0);
        z-index: 1;
    }
    .layer.layer2 {
        background: #fff;
        transform: skewX(-45deg) translateX(-100px);
        z-index: 3;
    }
    .layer.layer3 {
        background: $bg-ads-bright;
        transform: skewX(-45deg) translateX(-200px);
        z-index: 5;
    }
    .layer.layer4 {
        background: #fff;
        transform: skewX(-45deg) translateX(-300px);
        z-index: 10;
    }

    .layer.layer5 {
        background: $bg-ads-og;
        transform: skewX(45deg) translateX(0px);
        z-index: 2;
    }
    .layer.layer6 {
        background: $bg-ads-brighter;
        transform: skewX(45deg) translateX(-100px);
        z-index: 3;
    }
    .layer.layer7 {
        background: $bg-ads-bright;
        transform: skewX(45deg) translateX(-200px);
        z-index: 5;
    }
    .layer.layer8 {
        background: $bg-ads-darker;
        transform: skewX(45deg) translateX(-300px);
        z-index: 7;
    }
}
// Desktop
@media screen and (min-width: 1280px) {
    .layer {
        position: absolute;
        top: 0;
        left: -20%;
        width: 100%;
        height: 100vh;
        box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);
    }
    .layer.layer1 {
        background: $bg-ads-brighter;
        transform: skewX(-45deg) translateX(0);
        z-index: 1;
    }
    .layer.layer2 {
        background: #fff;
        transform: skewX(-45deg) translateX(-100px);
        z-index: 3;
    }
    .layer.layer3 {
        background: $bg-ads-bright;
        transform: skewX(-45deg) translateX(-200px);
        z-index: 5;
    }
    .layer.layer4 {
        background: #fff;
        transform: skewX(-45deg) translateX(-300px);
        z-index: 10;
    }

    .layer.layer5 {
        background: $bg-ads-og;
        transform: skewX(45deg) translateX(0px);
        z-index: 2;
    }
    .layer.layer6 {
        background: $bg-ads-brighter;
        transform: skewX(45deg) translateX(-100px);
        z-index: 3;
    }
    .layer.layer7 {
        background: $bg-ads-bright;
        transform: skewX(45deg) translateX(-200px);
        z-index: 5;
    }
    .layer.layer8 {
        background: $bg-ads-darker;
        transform: skewX(45deg) translateX(-300px);
        z-index: 7;
    }
}
// Language: scss