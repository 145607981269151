@import "responsive";
@import "colors";
@import "fonts";
@import "backStyle";
@tailwind base;
@tailwind components;
@tailwind utilities;
*{
    margin: 0;
    padding: 0;
    font-family: 'Futura-Book';
    box-sizing: border-box;
}
body{
    background: $bg-ads-black;
    width: 100%;
}