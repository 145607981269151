@font-face {
    font-family: 'Futura-Book';
    src: url('../assets/fonts/futura/Futura\ Book\ font.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face{
    font-family: 'Futura-Book';
    src: url('../assets/fonts/futura/Futura\ Book\ Italic\ font.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face{
    font-family: 'Futura-Medium';
    src: url('../assets/fonts/futura/futura\ medium\ bt.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Futura-Medium';
    src: url('../assets/fonts/futura/Futura\ Medium\ Italic\ font.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Futura-Bold';
    src: url('../assets/fonts/futura/Futura\ Bold\ font.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Futura-Bold';
    src: url('../assets/fonts/futura/Futura\ Bold\ Italic\ font.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Futura-Heavy';
    src: url('../assets/fonts/futura/Futura\ Heavy\ font.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Futura-Heavy';
    src: url('../assets/fonts/futura/Futura\ Heavy\ Italic\ font.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
