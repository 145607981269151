$color-original-ads: #0079a9;
$color-azul-ads: #0078a9;
$color-azul-ads-2: #0096D3;
$color-azul-ads-3: #00B4FE;
$color-azul-ads-4: #001D29;
$color-azul-ads-5: #003C54;
$color-azul-ads-6: #005A7E;
$color-naranja-ads: #A93100;
$color-gris-ads: #515B63;
$color-negro-ads: #1F2729;
$bg-ads-og: #0079A9;
$bg-ads-bright: #0097D3;
$bg-ads-brighter: #00B6FE;
$bg-ads-dark: #005B7E;
$bg-ads-darker: #003C54;
$bg-ads-black: #001E29;
